<template>
  <div>
    <div
      class="text-detail"
      v-if="VideoDetail && Object.keys(VideoDetail).length"
    >
      <div class="content mt-8">
        <div class="text-detail__title" v-html="VideoDetail.name"></div>
      </div>
      <Breadcrumbs
        :pages="[
          { name: 'Библиотека', link: { name: 'Library' } },
          {
            name: 'Видеоматериалы',
            link: {
              name: 'VideoMaterials',
            },
          },
          {
            name: VideoDetail.name,
          },
        ]"
      />
      <div class="content">
        <div class="text-detail__row">
          <div class="text-detail__body">
            <div class="material__tags-wrap">
              <div class="material__tags">
                <div class="material__tag" v-if="VideoDetail.type">
                  {{ VideoDetail.type.name }}
                </div>
                <div
                  class="material__tag"
                  v-for="nosology in VideoDetail.nosology"
                  :key="`nos${nosology.id}`"
                  v-html="nosology.title"
                ></div>
                <div
                  class="material__tag"
                  v-for="disease in VideoDetail.disease"
                  :key="`dis${disease.id}`"
                  v-html="disease.title"
                ></div>
                <div
                  class="material__tag material__tag_new"
                  v-if="VideoDetail.is_new && false"
                >
                  Новинка
                </div>
              </div>
              <Favorite
                class="large"
                v-if="!$route.query.access"
                :watchLater="VideoDetail.watch_later"
                :favorite="VideoDetail.favorite"
                @watchLater="
                  watchLater(
                    VideoDetail.watch_later ? 'DELETE' : 'POST',
                    VideoDetail.slug,
                    null,
                    true
                  )
                "
                @favorite="
                  favorite(
                    VideoDetail.favorite ? 'DELETE' : 'POST',
                    VideoDetail.slug,
                    null,
                    true
                  )
                "
              />
            </div>
            <video
              controls
              :src="$root.host + VideoDetail.video"
              :poster="
                VideoDetail.announce ? $root.host + VideoDetail.announce : null
              "
              v-show="VideoDetail.video"
              ref="videoElement"
            ></video>
            <div
              v-show="VideoDetail.description"
              class="text-detail__subtitle"
              v-html="VideoDetail.description"
              ref="description"
            ></div>
            <div
              v-if="
                VideoDetail.survey && filteredSurveyQuestions.questions.length
              "
              class="button button_pink mt-8"
              @click="openSurvey"
            >
              Пройти опрос
            </div>
          </div>
          <div class="sticky">
            <RightAside
              :items="asideItems"
              @medicationsClick="medicationsClick"
              @calendarClick="calendarClick"
              @nosologyClick="nosologyClick"
            />
          </div>
        </div>
      </div>
      <LibrarySlider
        v-if="sliderItems.length"
        :nosology="VideoDetail.nosology[0] || ''"
        :items="sliderItems"
        :video="true"
        :page="'VideoDetail'"
        @clickMaterials="clickMaterials"
        @toNosol="toNosol"
      />
      <PopUpDefault
        @close="closeSurvey"
        v-if="survey.opened && filteredSurveyQuestions.questions.length"
        class="profiling-modal__survey"
      >
        <LibrarySurvey
          @close="closeSurvey"
          @closeAfterSend="closeAfterSend"
          :survey="filteredSurveyQuestions"
          :content_type="'video'"
          :material_slug="VideoDetail.slug"
        />
      </PopUpDefault>
    </div>
    <div v-else-if="VideoDetail === 404"><Choch class="inPage" /></div>
    <div v-else-if="VideoDetail === false"><Error class="inPage" /></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Breadcrumbs from "../../components/pageComponents/Breadcrumbs.vue";
import Choch from "@/views/404.vue";
import Error from "@/views/Error.vue";
import { bus } from "@/main";
import "@/assets/scss/detail_content.scss";
import RightAside from "../../components/pageComponents/RightAside.vue";
import LibrarySlider from "@/components/pageComponents/LibrarySlider.vue";
import Favorite from "../../components/pageComponents/Favorite.vue";
import LibrarySurvey from "@/components/pageComponents/LibrarySurveyModal.vue";
import PopUpDefault from "@/components/pageComponents/PopUpDefault.vue";

export default {
  metaInfo() {
    if (this.VideoDetail.name) {
      return { title: this.VideoDetail.name.replace(/<[^>]*>?/gm, "") };
    } else {
      return { title: this.VideoDetail.name };
    }
  },
  name: "VideoDetail",
  components: {
    Breadcrumbs,
    Choch,
    RightAside,
    LibrarySlider,
    Error,
    Favorite,
    LibrarySurvey,
    PopUpDefault,
  },
  data: () => ({
    timer: null,
    percent: 0,
    survey: {
      opened: false,
      survey: null,
    },
  }),
  computed: {
    ...mapGetters(["VideoDetail", "VideoList", "user"]),
    filteredSurveyQuestions() {
      return {
        ...this.VideoDetail.survey,
        questions: [
          ...this.VideoDetail.survey.questions.filter((q) => !q.is_completed),
        ],
      };
    },
    loadedRefs() {
      return this.$refs;
    },
    sliderItems() {
      return this.VideoList
        ? this.VideoList.filter((el) => el.slug !== this.VideoDetail.slug)
        : [];
    },
    filter() {
      if (this.VideoDetail && Object.keys(this.VideoDetail).length) {
        return {
          therapeutic_areas: [this.VideoDetail.nosology[0]?.slug],
        };
      } else {
        return {};
      }
    },
    asideItems() {
      if (this.VideoDetail && Object.keys(this.VideoDetail).length) {
        return [
          {
            title: "Препараты АЗ",
            // descr: this.VideoDetail.nosology.map((el) => el.title).join(","),
            image: require("@/assets/img/cardio-img/aside-1.jpg"),
            image_desktop: require("@/assets/img/cardio-img/aside-1_small.jpg"),
            theme: "white",
            event: "medicationsClick",
            to: {
              name: "Medications",
              query: {
                therapeutic_areas: this.VideoDetail.nosology
                  .map((el) => el.slug)
                  .join(","),
              },
            },
          },
          {
            title: "Календарь мероприятий",
            image: require("@/assets/img/cardio-img/aside-2.jpg"),
            image_desktop: require("@/assets/img/cardio-img/aside-2_small.jpg"),
            event: "calendarClick",
            to: {
              name: "Events",
              query: {
                therapeutic_areas: this.VideoDetail.nosology
                  .map((el) => el.slug)
                  .join(","),
              },
            },
          },
          {
            title: "Терапевтические <br>области",
            image: require("@/assets/img/cardio-img/aside-4.jpg"),
            image_desktop: require("@/assets/img/cardio-img/aside-4_small.jpg"),
            event: "nosologyClick",
            to: { name: "Nosology" },
          },
        ];
      } else {
        return [];
      }
    },
  },
  methods: {
    ...mapActions([
      "fetchVideoDetail",
      "fetchVideoMaterials",
      "fetchVideoWatchLater",
      "fetchVideoFavorite",
      "fetchVideoHistory",
    ]),
    closeSurvey() {
      this.$set(this.survey, "opened", false);
      bus.$emit("scrollLock", false);
    },
    openSurvey() {
      this.$set(this.survey, "opened", true);
    },
    async closeAfterSend() {
      bus.$emit("scrollLock", false);
      if (!this.$route.query.access) {
        await this.fetchVideoDetail({ slug: this.$route.params.slug }).then(
          () => {
            if (this.VideoDetail.survey) {
              this.$set(this.survey, "survey", this.VideoDetail.survey);
            }
          }
        );
      } else {
        this.$set(this.survey, "opened", false);
      }
    },
    toTop() {
      document.body.scrollIntoView({ behavior: "smooth" });
    },
    async watchLater(method, slug, filter, favoritePage) {
      await this.fetchVideoWatchLater({
        method: method,
        slug: slug,
        filter: filter,
        favoritePage: favoritePage,
      });
      await this.fetchVideoDetail({ slug: this.$route.params.slug });
    },
    async favorite(method, slug, filter, favoritePage) {
      await this.fetchVideoFavorite({
        method: method,
        slug: slug,
        filter: filter,
        favoritePage: favoritePage,
      });
      await this.fetchVideoDetail({ slug: this.$route.params.slug });
    },
    cyclicVideo(duration) {
      const vm = this;
      this.timer = setTimeout(() => {
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "video 30 sec", {
            "video 30 sec": {
              "video name": vm.VideoDetail.name,
              "video duration": duration,
              ...this.$root.ymFields,
            },
          });
        }
        vm.cyclicVideo();
      }, 30000);
    },
    medicationsClick(item) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "videomaterial page medications click", {
          "videomaterial page medications click": {
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(item.to).catch(() => {});
    },
    calendarClick(item) {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "videomaterial page events click", {
          "videomaterial page events click": {
            ...this.$root.ymFields,
          },
        });
      }
      this.$router.push(item.to).catch(() => {});
    },
    nosologyClick(item) {
      if (typeof ym !== "undefined") {
        ym(
          91468266,
          "reachGoal",
          "videomaterial page therapeutic areas click",
          {
            "videomaterial page therapeutic areas click": {
              ...this.$root.ymFields,
            },
          }
        );
      }
      this.$router.push(item.to).catch(() => {});
    },
    clickMaterials(name) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "videomaterial page more materials click", {
          "videomaterial page more materials click": {
            "videomaterials page name": name,
            ...this.$root.ymFields,
          },
        });
      }
    },
    toNosol(title, to) {
      const vm = this;
      if (typeof ym !== "undefined") {
        ym(
          91468266,
          "reachGoal",
          "videomaterial page current therapeutic areas click",
          {
            "videomaterial page current therapeutic areas click": {
              "therapeutic area": title,
              ...this.$root.ymFields,
            },
          }
        );
      }
      this.$router.push(to).catch(() => {});
    },
  },
  async mounted() {
    const vm = this;
    setTimeout(() => {
      if (
        this.loadedRefs[`description`] &&
        this.loadedRefs[`description`].querySelector(".timecodes")
      ) {
        this.loadedRefs[`description`]
          .querySelectorAll(".timecodes__time")
          .forEach(async function (item) {
            item.addEventListener("click", function () {
              let time = this.innerText.split(":");
              let hours = parseInt(time[0].padStart(2, 0));
              let minutes = parseInt(time[1].padStart(2, 0));
              let seconds = parseInt(time[2].padStart(2, 0));
              let result = hours * 60 * 60 + minutes * 60 + seconds;
              vm.loadedRefs[`videoElement`].currentTime = result;
              vm.loadedRefs[`videoElement`].scrollIntoView({
                behavior: "smooth",
              });
            });
          });
      }
      if (this.loadedRefs[`videoElement`]) {
        const video = this.loadedRefs[`videoElement`];
        // if(this.$route.query.t) {
        //   video.currentTime = this.$route.query.t
        // }
        video.addEventListener("play", function () {
          if (this.currentTime === 0) {
            if (typeof ym !== "undefined") {
              ym(91468266, "reachGoal", "video start", {
                "video start": {
                  "video name": vm.VideoDetail.name,
                  "video duration": Math.round(this.duration),
                  ...vm.$root.ymFields,
                },
              });
            }
          } else {
            if (typeof ym !== "undefined") {
              ym(91468266, "reachGoal", "video resume", {
                "video resume": {
                  "video name": vm.VideoDetail.name,
                  "video duration": Math.round(this.duration),
                  ...vm.$root.ymFields,
                },
              });
            }
          }
          vm.cyclicVideo(Math.round(this.duration));
        });
        video.addEventListener("pause", function () {
          if (this.currentTime !== this.duration) {
            if (typeof ym !== "undefined") {
              ym(91468266, "reachGoal", "video pause", {
                "video pause": {
                  "video name": vm.VideoDetail.name,
                  "video duration": Math.round(this.duration),
                  ...vm.$root.ymFields,
                },
              });
            }
          }
          clearTimeout(vm.timer);
          vm.timer = null;
        });
        video.addEventListener("ended", function () {
          if (typeof ym !== "undefined") {
            ym(91468266, "reachGoal", "video end", {
              "video end": {
                "video name": vm.VideoDetail.name,
                "video duration": Math.round(this.duration),
                ...vm.$root.ymFields,
              },
            });
          }
        });
        video.addEventListener("timeupdate", function () {
          let percent = Math.ceil((this.currentTime / this.duration) * 100);
          if (this.percent !== percent && percent !== 0 && percent % 5 === 0) {
            if (typeof ym !== "undefined") {
              ym(91468266, "reachGoal", "video percentage watched", {
                "video percentage watched": {
                  "video percentage watched": percent,
                  ...vm.$root.ymFields,
                },
              });
            }
            this.percent = percent;
          }
        });
      }
    }, 300);
  },
  watch: {
    "$route.params.slug": {
      handler: async function () {
        if (!this.$route.query.access) {
          await this.fetchVideoDetail({ slug: this.$route.params.slug });
          await this.fetchVideoHistory(this.$route.params.slug);
        }
      },
      deep: true,
      immediate: true,
    },
    VideoDetail: {
      handler: async function () {
        if (this.VideoDetail && Object.keys(this.VideoDetail).length) {
          await this.fetchVideoMaterials(this.filter);
          localStorage.setItem("videoBlock", this.VideoDetail.slug);
        }

        if (this.VideoDetail.survey) {
          this.$set(this.survey, "survey", this.VideoDetail.survey);
          this.$set(this.survey, "opened", true);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.text-detail {
  padding-top: 64px;
  @media screen and (max-width: 1220px) {
    padding-top: 40px;
  }

  &__row {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 1fr 280px;
    column-gap: 136px;
    align-items: flex-start;

    @media screen and (max-width: 1220px) {
      grid-template-columns: 100%;
      row-gap: 40px;
    }
  }

  &__title {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 32px;
    }
  }
  &__subtitle {
    margin-bottom: 24px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 29px;
    color: #1f1f1f;
    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }

  &__buttons {
    margin-top: 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media screen and (max-width: 1220px) {
      margin-bottom: 80px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 0;
      flex-direction: column;
      align-items: stretch;
    }
  }
  &__load {
    width: 110px;
    @media screen and (max-width: 767px) {
      width: 100%;
      margin-bottom: 12px;
    }
  }

  &__up {
    margin-left: auto;
    width: 213px;

    @media screen and (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
  }
}

.sticky {
  @media screen and (min-width: 1221px) {
    position: sticky;
    top: 115px;
  }
}

.material {
  &__tags-wrap {
    margin-bottom: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @media screen and (max-width: 1220px) {
      flex-direction: column;
    }
  }
  &__tags {
    margin-right: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    @media screen and (max-width: 1220px) {
      margin-bottom: 16px;
    }
    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  &__tag {
    padding-left: 10px;
    position: relative;
    margin-right: 8px;
    // margin-bottom: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
    display: inline-flex;
    align-items: center;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #3c4242;
    white-space: nowrap;
    // max-width: calc(50% - 16px);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: break-spaces;

    @media screen and (max-width: 767px) {
      margin-bottom: 8px;
    }

    &::before {
      position: absolute;
      top: 10px;
      left: 0;
      transform: translateY(-50%);
      width: 2px;
      height: 2px;
      background-color: #3c4242;
      border-radius: 50%;
      display: block;
      content: "";
    }

    &:last-child {
      margin-right: 0;
    }

    &_new {
      position: absolute;
      top: 8px;
      left: 8px;
      z-index: 4;
      display: inline-block;
      padding: 4px 8px;
      background: rgba(255, 255, 255, 0.8);
      border: 1px solid #d8dada;
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 10px;
      line-height: 13px;
      color: #1f1f1f;
    }
  }
}

.favorite {
  @media screen and (max-width: 767px) {
    width: 100%;
    margin-top: 16px;
  }
}
</style>

<style lang="scss">
.profiling-modal {
  &__survey {
    & .modal-popup {
      &__content {
        background: #fff;
        border-radius: 20px;
        padding: 0;
        @media screen and (max-width: 1220px) {
          padding: 16px;
        }
      }
    }
  }
}
</style>